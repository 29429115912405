<template>
  <ModalDialog v-model:visible="show" :header="t('label.document', 2)">
    <UploadFile :upload-action="doUpload"
                :uploaded-files="uploadedFiles"
                :delete-file-action="deleteFile"
                context="contratto"/>

    <template #footer>
      <Button :label="t('action.cancel')" icon="pi pi-times" class="p-button p-button-light" @click="hideAction"/>
    </template>

  </ModalDialog>
</template>

<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import eventService from "@/services/EventService";
import restService from "@/services/ContrattoRestService";
import ModalDialog from "@/components/common/ModalDialog";
import useMessage from "@/composable/useMessage";
import {useStore} from "vuex";
import UploadFile from "@/components/common/UploadFile";
import {useI18n} from "vue-i18n";

const store = useStore();
const {successWithKey} = useMessage();
const {t} = useI18n();
let show = ref(false);

const SHOW_CONTRATTO_DIALOG_EVENT = "showContrattoUploadFilesDialog";
let item = ref({});
let uploadedFiles = computed(() => store.getters['contratto/uploadedFiles']);


onMounted(() => {
  eventService.on(SHOW_CONTRATTO_DIALOG_EVENT, onShow)
});

onUnmounted(() => {
  eventService.off(SHOW_CONTRATTO_DIALOG_EVENT, onShow);
});


const onShow = (data) => {
  show.value = true;
  item.value = data;
  store.dispatch('contratto/loadAllUploadedFiles', item.value);
}

const hideAction = () => {
  show.value = false;
}

const doUpload = async (data) => {
  const documentType = data.documentType;
  await restService.uploadFiles(item.value, documentType, data);
  await store.dispatch('contratto/loadAllUploadedFiles', item.value);
  successWithKey('message.upload-success');
}

const deleteFile = async (data) => {
  const contratto = item.value;
  const fileName = data.nome;
  await restService.deleteFile(contratto, fileName);
  successWithKey('message.delete-success');
  await store.dispatch('contratto/loadAllUploadedFiles', item.value);
}

</script>

<style scoped>
</style>
