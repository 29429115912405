<template>
  <ModalDialog
      v-model:visible="show"
      :header="t('label.select-surveyor')"
      position="center"
      :maximizable="false"
      style="width: 30vw; height: 36vh"
      @hide="onHide">

    <div class="field col-12 md:col-12">
      <label for="verificatore2">{{ t('label.select-verificatore') }}</label>
      <div class="p-inputgroup">
        <Dropdown
            id="verificatore2"
            v-model="contratto.verificatore2"
            :options="verificatori"
            optionLabel="nomeBreve"
            :placeholder="t('message.select-option')"
            :show-clear="true"
            :filter="true"
            :class="errorStyleClass(v$, 'verificatore2')">
        </Dropdown>
        <Button @click="invitaSoggetto(Roles.SURVEYOR)"
                icon="pi pi-user-plus"
                :label="t('label.soggetto.invita-verificatore')"
                class="p-button-sm p-button-success invite-button"
                v-tooltip.top="t('label.soggetto.invita-verificatore')"/>
      </div>
      <ErrorMessages field="verificatore2"/>
    </div>

    <!-- actions -->
    <template #footer>
      <Button :label="t('action.cancel')" icon="pi pi-times" class="p-button p-button-light" @click="hide"/>
      <Button :label="t('action.save')" icon="pi pi-check" class="p-button p-button-success p-float-right"
              @click="save"/>
    </template>

  </ModalDialog>
</template>

<script setup>
import {onMounted, onUnmounted, ref, provide} from "vue";
import {useI18n} from "vue-i18n";
import {SHOW_SELECT_SURVEYOR_DIALOG, SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG} from "@/components/common/constants/Events";
import EventService from "@/services/EventService";
import ModalDialog from "@/components/common/ModalDialog";
import soggettoRestService from "@/services/SoggettoRestService";
import ErrorMessages from "@/components/common/ErrorMessages";
import useCommon from "@/composable/useCommon"
import useVuelidate from "@vuelidate/core";
import Roles from "@/components/common/constants/Roles";
import contrattoRestService from "@/services/ContrattoRestService";
import {helpers, required} from "@vuelidate/validators";
import useMessage from "@/composable/useMessage";
import {useStore} from "vuex";

const {t} = useI18n();
const show = ref(false);
const {success} = useMessage();
const {errorStyleClass} = useCommon();
const store = useStore();

const contratto = ref();
const verificatori = ref([]);


const rules = {
  verificatore2: {required: helpers.withMessage(t("validation.required", {field: t("label.verificatore")}), required)}
};
const v$ = useVuelidate(rules, contratto, {$scope: 'selectVerificatore'});

onMounted(() => {
  EventService.on(SHOW_SELECT_SURVEYOR_DIALOG, onShow);
})

onUnmounted(() => {
  EventService.off(SHOW_SELECT_SURVEYOR_DIALOG, onShow);
})

const onShow = async (contr) => {
  contratto.value = contr;
  show.value = true;
  verificatori.value = await soggettoRestService.surveyors();


  contratto.value.verificatore2 = null;
  if (null != contratto.value.verificatore2Eori && null != verificatori.value) {
    contratto.value.verificatore2 = verificatori.value.filter(s => s.eori === contratto.value.verificatore2Eori)[0];
  }

}

const invitaSoggetto = (ruolo) => {
  EventService.emit(SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG, {ruolo: ruolo});
}

const onHide = () => {
  v$.value.$reset();
}

const hide = () => {
  show.value = false;
}

const save = async () => {
  await v$.value.$validate();
  if (!v$.value.$invalid) {
    await contrattoRestService.selectSecondSurveyor(contratto.value, contratto.value.verificatore2);
    await store.dispatch("contratto/loadAll"); // operazone onerosa, aggiornamento selettivo... avendo tempo...
    success();
    hide();
  }
}

provide("v$", v$);
</script>

<style scoped>

</style>