<template>
  <ModalDialog
      v-model:visible="show"
      :header="t('action.select-soggetti')"
      position="center"
      :maximizable="false"
      style="width: 35vw; height: 70vh"
      @hide="onHide">

    <!-- spedizioniere -->
    <div class="field col-12 md:col-12">
      <label for="spedizioniere">{{ t('label.select-spedizioniere') }}</label>
      <div class="p-inputgroup">
        <Dropdown
            id="spedizioniere"
            v-model="contratto.spedizioniere"
            :options="spedizionieri"
            option-label="nomeBreve"
            :placeholder="t('message.select-option')"
            :show-clear="true"
            :filter="true"
            :class="errorStyleClass(v$, 'spedizioniere')">
        </Dropdown>
        <Button @click="invitaSoggetto(Roles.SPEDIZIONIERE)"
                icon="pi pi-user-plus"
                :label="t('label.soggetto.invita-spedizioniere')"
                class="p-button-sm p-button-success invite-button"
                v-tooltip.top="t('label.soggetto.invita-spedizioniere')"/>
      </div>
      <ErrorMessages field="spedizioniere"/>
    </div>

    <!-- verificatore -->
    <div class="field col-12 md:col-12">
      <label for="verificatore">{{ t('label.select-verificatore') }}</label>
      <div class="p-inputgroup">
        <Dropdown
            id="verificatore"
            v-model="contratto.verificatore"
            :options="verificatori"
            optionLabel="nomeBreve"
            :placeholder="t('message.select-option')"
            :show-clear="true"
            :filter="true"
            :class="errorStyleClass(v$, 'verificatore')">
        </Dropdown>
        <Button @click="invitaSoggetto(Roles.SURVEYOR)"
                icon="pi pi-user-plus"
                :label="t('label.soggetto.invita-verificatore')"
                class="p-button-sm p-button-success invite-button"
                v-tooltip.top="t('label.soggetto.invita-verificatore')"/>
      </div>
      <ErrorMessages field="verificatore"/>
    </div>

    <!-- caricatore -->
    <div class="field col-12 md:col-12">
      <label for="caricatore">{{ t('label.select-caricatore') }}</label>
      <div class="p-inputgroup">
        <Dropdown
            id="caricatore"
            v-model="contratto.caricatore"
            :options="caricatori"
            optionLabel="nomeBreve"
            :placeholder="t('message.select-option')"
            :show-clear="true"
            :filter="true"
            :class="errorStyleClass(v$, 'caricatore')">
        </Dropdown>
        <Button @click="invitaSoggetto(Roles.CARICATORE)"
                icon="pi pi-user-plus"
                :label="t('label.soggetto.invita-caricatore')"
                class="p-button-sm p-button-success invite-button"
                v-tooltip.top="t('label.soggetto.invita-caricatore')"/>
      </div>
      <ErrorMessages field="caricatore"/>
    </div>

    <!-- scaricatore -->
    <div class="field col-12 md:col-12">
      <label for="scaricatore">{{ t('label.select-scaricatore') }}</label>
      <div class="p-inputgroup">
        <Dropdown
            id="scaricatore"
            v-model="contratto.scaricatore"
            :options="scaricatori"
            optionLabel="nomeBreve"
            :placeholder="t('message.select-option')"
            :show-clear="true"
            :filter="true"
            :class="errorStyleClass(v$, 'scaricatore')">
        </Dropdown>
        <Button @click="invitaSoggetto(Roles.SCARICATORE)"
                icon="pi pi-user-plus"
                :label="t('label.soggetto.invita-scaricatore')"
                class="p-button-sm p-button-success invite-button"
                v-tooltip.top="t('label.soggetto.invita-scaricatore')"/>
      </div>
      <ErrorMessages field="scaricatore"/>
    </div>

    <!-- custom_broker -->
    <div class="field col-12 md:col-12">
      <label for="custom_broker">{{ t('label.select-custom-broker') }}</label>
      <div class="p-inputgroup">
        <Dropdown
            id="custom_broker"
            v-model="contratto.customBroker"
            :options="customBrokers"
            optionLabel="nomeBreve"
            :placeholder="t('message.select-option')"
            :show-clear="true"
            :filter="true"
            :class="errorStyleClass(v$, 'custom_broker')">
        </Dropdown>
        <Button @click="invitaSoggetto(Roles.CUSTOM_BROKER)"
                icon="pi pi-user-plus"
                :label="t('label.soggetto.invita-custom-broker')"
                class="p-button-sm p-button-success invite-button"
                v-tooltip.top="t('label.soggetto.invita-custom-broker')"/>
      </div>
      <ErrorMessages field="custom_broker"/>
    </div>


    <!-- actions -->
    <template #footer>
      <Button :label="t('action.cancel')" icon="pi pi-times" class="p-button p-button-light" @click="hide"/>
      <Button :label="t('action.save')" icon="pi pi-check" class="p-button p-button-success p-float-right"
              @click="saveAction" v-permission="[Roles.EXECUTION_MANAGER]"/>
    </template>

  </ModalDialog>
</template>

<script setup>
import {onMounted, onUnmounted, provide, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import useCommon from "@/composable/useCommon";
import eventService from "@/services/EventService";
import {
  SHOW_SELECT_AZIENDE_DIALOG,
  SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG
} from "@/components/common/constants/Events";
import soggettoRestService from "@/services/SoggettoRestService";
import ErrorMessages from "@/components/common/ErrorMessages";
import ModalDialog from "@/components/common/ModalDialog";
import EventService from "@/services/EventService";
import {useI18n} from "vue-i18n";
import useLogger from "@/composable/useLogger";
import Roles from "@/components/common/constants/Roles";
import restService from "@/services/ContrattoRestService";
import useMessage from "@/composable/useMessage";
import {useStore} from "vuex";


const {t} = useI18n();
const {logger} = useLogger();
const {success} = useMessage();
const store = useStore();

const {errorStyleClass} = useCommon();

const contratto = ref({});
const show = ref(false);
const spedizionieri = ref([]);
const caricatori = ref([]);
const scaricatori = ref([]);
const verificatori = ref([]);
const customBrokers = ref([]);

// validation rules
const rules = {
  spedizioniere: {required: helpers.withMessage(t("validation.required", {field: t("label.spedizioniere")}), required)},
  verificatore: {required: helpers.withMessage(t("validation.required", {field: t("label.verificatore")}), required)}
};
const v$ = useVuelidate(rules, contratto, {$scope: 'selectExManager'});

onMounted(() => {
  eventService.on(SHOW_SELECT_AZIENDE_DIALOG, onShow);
});

onUnmounted(() => {
  eventService.off(SHOW_SELECT_AZIENDE_DIALOG, onShow);
});

const onShow = async (contr) => {

  show.value = true;

  spedizionieri.value = await soggettoRestService.spedizionieri();
  caricatori.value = await soggettoRestService.caricatori();
  scaricatori.value = await soggettoRestService.scaricatori();
  verificatori.value = await soggettoRestService.surveyors();
  customBrokers.value = await soggettoRestService.customBrokers();

  contratto.value = contr;

  // spedizioniere (locale) da globale
  if (null != contratto.value) {

    // railforwarder
    contratto.value.spedizioniere = null;
    if (null != contratto.value.spedizioniereEori && null != spedizionieri.value) {
      contratto.value.spedizioniere = spedizionieri.value.filter(s => s.eori === contratto.value.spedizioniereEori)[0];
    }

    // loader
    contratto.value.caricatore = null;
    if (null != contratto.value.caricatoreEori && null != caricatori.value) {
      contratto.value.caricatore = caricatori.value.filter(s => s.eori === contratto.value.caricatoreEori)[0];
    }

    // unloader
    contratto.value.scaricatore = null;
    if (null != contratto.value.scaricatoreEori && null != scaricatori.value) {
      contratto.value.scaricatore = scaricatori.value.filter(s => s.eori === contratto.value.scaricatoreEori)[0];
    }

    // surveyor
    contratto.value.verificatore = null;
    if (null != contratto.value.verificatoreEori && null != verificatori.value) {
      contratto.value.verificatore = verificatori.value.filter(s => s.eori === contratto.value.verificatoreEori)[0];
    }

    // custom broker
    contratto.value.customBroker = null;
    if (null != contratto.value.customBrokerEori && null != customBrokers.value) {
      contratto.value.customBroker = customBrokers.value.filter(s => s.eori === contratto.value.customBrokerEori)[0];
    }

  }

}

const onHide = () => {
  v$.value.$reset();
};

const invitaSoggetto = (ruolo) => {
  EventService.emit(SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG, {ruolo: ruolo});
}

const saveAction = async () => {
  await v$.value.$validate();
  if (!v$.value.$invalid) {

    const payload = {
      spedizioniereId: contratto.value.spedizioniere.id,
      verificatoreId: contratto.value.verificatore.id,
    };

    if (typeof contratto.value.caricatore !== 'undefined' && null !== contratto.value.caricatore) {
      payload.caricatoreId = contratto.value.caricatore.id;
    }
    if (typeof contratto.value.scaricatore !== 'undefined' && null !== contratto.value.scaricatore) {
      payload.scaricatoreId = contratto.value.scaricatore.id
    }
    if (typeof contratto.value.customBroker !== 'undefined' && null !== contratto.value.customBroker) {
      payload.customBrokerId = contratto.value.customBroker.id
    }

    await restService.selectSoggetti(contratto.value.id, payload);
    await store.dispatch("contratto/loadAll"); // operazone onerosa, aggiornamento selettivo... avendo tempo...
    success();
    hide();

  } else {
    logger.error("Validation errors ", v$.value.$errors);
  }
}

const hide = () => {
  show.value = false;
}

provide("v$", v$);
</script>

<style scoped>

.invite-button {
  width: 13vw !important;
  font-size: 1.1rem !important;
}
</style>
