<template>
  <ModalDialog
      v-model:visible="show"
      :header="t('label.select-execution-manager')"
      position="center"
      :maximizable="false"
      style="width: 30vw; height: 36vh"
      @hide="onHide">

    <!-- select execution manager -->
    <div class="field col-12 md:col-12">
      <label for="executionManager">{{ t('label.select-execution-manager') }}</label>
      <div class="p-inputgroup">
        <AutoComplete id="executionManager"
                      v-model="contratto.executionManager"
                      field="username"
                      :suggestions="filteredUsers"
                      :min-length="3"
                      :forceSelection="true"
                      :placeholder="t('message.autocomplete.hint-min-chars',{num: 3})"
                      :class="errorStyleClass(v$, 'executionManager')"
                      @complete="searchUsers($event)"/>
        <Button @click="createExecutionManager" icon="pi pi-user-plus" class="p-button-sm"
                v-tooltip.top="t('action.add-new')"/>
      </div>
      <ErrorMessages field="executionManager"/>
    </div>


    <!-- Dialogs -->
    <InsertExecutionManagerDialog @on-hide="onInsertExecutionManagerDialogHide"/>

    <!-- actions -->
    <template #footer>
      <Button :label="t('action.cancel')" icon="pi pi-times" class="p-button p-button-light" @click="hideAction"/>
      <Button :label="t('action.save')" icon="pi pi-check" class="p-button p-button-success p-float-right"
              @click="saveItemAction" v-permission="[Roles.BUYER, Roles.SELLER]"/>
    </template>

  </ModalDialog>
</template>

<script setup>
import {onMounted, onUnmounted, provide} from "vue";
import eventService from "@/services/EventService";
import utenteRestService from "@/services/UtenteRestService";
import {ref} from 'vue';
import {
  SHOW_SELECT_EXECUTION_MANAGER_DIALOG,
  SHOW_INSERT_EXECUTION_MANAGER_DIALOG
} from "@/components/common/constants/Events";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import useCommon from "@/composable/useCommon";
import ErrorMessages from "@/components/common/ErrorMessages";
import InsertExecutionManagerDialog from "@/components/contratto/InsertExecutionManagerDialog";
import ModalDialog from "@/components/common/ModalDialog";
import {isEmpty} from "lodash";
import {useI18n} from "vue-i18n";
import useLogger from "@/composable/useLogger";
import Roles from "@/components/common/constants/Roles";
import useMessage from "@/composable/useMessage";
import contrattoRestService from "@/services/ContrattoRestService";
import {useStore} from "vuex";

const {t} = useI18n();
const store = useStore();
const {errorStyleClass} = useCommon();
const {logger} = useLogger();
const {success} = useMessage();

const contratto = ref({});
const show = ref(false);
const filteredUsers = ref();

// validation rules
const rules = {
  executionManager: {
    required: helpers.withMessage(t("validation.required", {field: t("label.executionManager")}), required),
  },
};
const v$ = useVuelidate(rules, contratto, {$scope: 'selectExManager'});

const showDialogAction = async (ctrn) => {
  contratto.value = ctrn;
  show.value = true;

  // find execution manager
  if (!isEmpty(contratto.value.executionManagerId)) {
    const executionManager = await utenteRestService.findExecutionManager(contratto.value.executionManagerId);
    contratto.value.executionManager = executionManager;
  }
}

onMounted(() => {
  eventService.on(SHOW_SELECT_EXECUTION_MANAGER_DIALOG, showDialogAction);
});

onUnmounted(() => {
  eventService.off(SHOW_SELECT_EXECUTION_MANAGER_DIALOG, showDialogAction);
});

const onHide = () => {
  v$.value.$reset();
};

const searchUsers = async (e) => {
  filteredUsers.value = await utenteRestService.findExmanByUsername(e.query);
}

const createExecutionManager = () => {
  const data = {item: {}, mode: 'insert'};
  eventService.emit(SHOW_INSERT_EXECUTION_MANAGER_DIALOG, data);
}

const saveItemAction = async () => {
  await v$.value.$validate();
  if (!v$.value.$invalid) {
    const params = {idContratto: contratto.value.id, executionManagerId: contratto.value.executionManager.id};
    console.log("PARAMS   ", contratto.value);
    await contrattoRestService.selectExecutionManager(params);
    await store.dispatch("contratto/loadAll");
    success();
    hideAction();
  } else {
    logger.error("Validation errors ", v$.value.$errors);
  }
}

const hideAction = () => {
  show.value = false;
}

const onInsertExecutionManagerDialogHide = async (data) => {
  if (!isEmpty(data) && !isEmpty(data.executionManager)) {
    await searchUsers({query: data.executionManager.username});
    contratto.value.executionManager = data.executionManager;
  }
}

provide("v$", v$);
</script>

<style scoped>

</style>
