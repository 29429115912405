<template>
  <Button
      icon="pi pi-chart-bar"
      class="p-button-warning mr-2"
      v-tooltip.top="t('action.flow-diagram')"
      @click="showWorkflowDiagramDialog"/>
</template>

<script setup>
import EventService from "@/services/EventService";
import {SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG} from "@/components/common/constants/Events";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const props = defineProps({contratto: {required: true}});


const showWorkflowDiagramDialog = () => {
  const contratto = props.contratto;
  const workflow = JSON.parse(contratto.workflow.context);
  EventService.emit(SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG, {contratto, viaggio: null, workflow});
};

</script>

<style scoped>

</style>