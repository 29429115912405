<template>
  <Button
      icon="pi pi-file-pdf"
      class="p-button-help mr-2"
      v-tooltip.top="t('label.contratto.export.pdf')"
      @click="showExportPdfDialog"
      v-if="checkPermission([Roles.BROKER, Roles.BUYER, Roles.SELLER])"/>
</template>

<script setup>
import useAcl from "@/composable/useAcl";
import {useI18n} from "vue-i18n";
import contrattoRestService from "@/services/ContrattoRestService";
import Roles from "@/components/common/constants/Roles";

const props = defineProps({
  contratto: {required: true}
})

const {t} = useI18n();
const {checkPermission} = useAcl();

const showExportPdfDialog = () => {
  contrattoRestService.exportPdf(props.contratto);
}

</script>

<style scoped>

</style>
