<template>
  <Dialog v-model:visible="show"
          :header="t('label.soggetto.invia-richiesta')"
          :draggable="false"
          :modal="true"
          style="width: 40vw"
          @hide="onHide">
    <div class="p-fluid grid">
      <div class="col-12 md:col-12">
        <div class="p-fluid formgrid grid">

          <!-- partita iva -->
          <div class="field col-12 md:col-12">
            <label for="eori">{{ t("label.soggetto.piva") }}</label>
            <InputText id="eori" name="eori"
                       v-model="richiesta.eori"
                       :class="[errorStyleClass(v$, 'eori'), 'vat']"
                       autofocus/>
            <ErrorMessages field="eori"/>
          </div>

          <!-- email -->
          <div class="field col-12 md:col-6">
            <label for="email">{{ t("label.soggetto.email") }}</label>
            <InputText id="email" name="email"
                       v-model="richiesta.email"
                       :class="[errorStyleClass(v$, 'email'),'text']"/>
            <ErrorMessages field="email"/>
          </div>

          <!-- nome -->
          <div class="field col-12 md:col-6">
            <label for="nome">{{ t("label.soggetto.nome") }}</label>
            <InputText id="nome" name="nome"
                       v-model="richiesta.nome"
                       :class="[errorStyleClass(v$, 'nome'),'text']"/>
            <ErrorMessages field="nome"/>
          </div>

        </div>

      </div>
    </div>

    <!-- footer -->
    <template #footer>
      <div class="text-right">
        <Button :label="t('action.invia-richiesta')" class="p-button p-button-success" @click="sendRequest"/>
      </div>
    </template>

  </Dialog>
</template>

<script setup>
import {onMounted, onUnmounted, ref} from "vue";
import eventService from "@/services/EventService";
import {
  SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG,
} from "@/components/common/constants/Events";
import {useI18n} from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import useCommon from "@/composable/useCommon";
import ErrorMessages from "@/components/common/ErrorMessages";
import {provide} from "vue";
import useMessage from "@/composable/useMessage";
import soggettoRichiesteRestService from "@/services/SoggettoRichiesteRestService";


const {t} = useI18n();
const {errorStyleClass} = useCommon();
const {success} = useMessage();

const show = ref(false);
const richiesta = ref();

const rules = {
  eori: {required: helpers.withMessage(t("validation.required", {field: t("label.soggetto.piva")}), required)},
  nome: {required: helpers.withMessage(t("validation.required", {field: t("label.soggetto.nome")}), required)},
  email: {required: helpers.withMessage(t("validation.required", {field: t("label.soggetto.email")}), required), email}
};
const v$ = useVuelidate(rules, richiesta, {$scope: false});

onMounted(() => {
  eventService.on(SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG, onShow);
})

onUnmounted(() => {
  eventService.off(SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG, onShow);
})

function onShow(params) {
  show.value = true;
  richiesta.value = {ruolo: params.ruolo};
}

async function sendRequest() {
  await v$.value.$validate();
  if (!v$.value.$invalid) {
    await soggettoRichiesteRestService.sendInvitation(richiesta.value);
    success();
    show.value = false;
  } else {
    console.log("Errore in fase di validazione ", v$.value.$errors);
  }
}

function onHide() {
  v$.value.$reset();
}

provide("v$", v$);
</script>

<style scoped>

.vat {
  letter-spacing: .8rem;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.text {
  letter-spacing: .2rem;
  font-size: 16px;
}
</style>
