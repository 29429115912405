<template>
  <div class="card" v-if="checkPermission([Roles.BROKER])">
    <DataTable
        dataKey="id"
        v-model:value="bozzeContratti"
        :paginator="true"
        :rows="datatableConfig.rows"
        :rowsPerPageOptions="datatableConfig.rowsPerPageOptions"
        :paginatorTemplate="datatableConfig.paginatorTemplate"
        :currentPageReportTemplate="datatableConfig.currentPageReportTemplate"
        responsiveLayout="scroll">

      <template #header>
        <div class="table-header flex flex-column md:flex-row md:justify-content-between">
          <h4 class="m-0 uppercase">{{ t("label.contratto.bozza", 2) }}</h4>
        </div>
      </template>

      <Column field="codice" :header="t('label.contratto.riferimenti.numeroContrattoBrokeraggio')" :sortable="true"/>
      <Column field="datains" :header="t('label.dataInserimento')" :sortable="true">
        <template #body="slotProps">
          {{ dateTimeFormat(slotProps.data.datains) }}
        </template>
      </Column>

      <Column field="datamod" :header="t('label.dataModifica')" :sortable="true">
        <template #body="slotProps">
          {{ dateTimeFormat(slotProps.data.datamod) }}
        </template>
      </Column>

      <Column field="noteBozza" :header="t('label.contratto.note', 2)" :sortable="false">
        <template #body="slotProps">
          <NoteBozzaContratto :bozza="slotProps.data" />
        </template>
      </Column>

      <Column class="text-left">
        <template #body="slotProps">

          <Button icon="pi pi-pencil"
                  @click="showContrattoWizard(slotProps.data)"
                  v-tooltip.top="t('action.edit')"
                  class="p-button-primary mr-2"
                  v-if="slotProps.data.bozza"/>

        </template>
      </Column>

    </DataTable>
  </div>
</template>

<script setup>
import Roles from "@/components/common/constants/Roles";
import useAcl from "@/composable/useAcl";
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import useDateTime from "@/composable/useDateTime";
import router from "@/router";
import NoteBozzaContratto from "@/components/contratto/NoteBozzaContratto";

const store = useStore();
const {t} = useI18n();

const {checkPermission} = useAcl();
const {dateTimeFormat} = useDateTime();

const datatableConfig = store.getters["config/datatable"];

const bozzeContratti = computed(() => store.getters['contratto/drafts']);


onMounted(() => {
  store.dispatch("contratto/loadAllDrafts");
})



async function showContrattoWizard(contratto) {
  await store.dispatch("contrattoWizard/setContratto", contratto);
  await router.push("/contratto/wizard/contratto");
}

</script>

<style scoped>
</style>
