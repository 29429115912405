<template>
  <div class="p-fluid formgrid grid mt-4 ">

    <div class="field col-4">
      <Dropdown v-model="documentType" :options="documentTypes" option-label="label" :showClear="true" :filter="true">

        <template #option="slotProps">
          <i :class="slotProps.option.icon"></i>
          <div class="dropdown title">{{ slotProps.option.label }}</div>
          <div class="dropdown description">{{ slotProps.option.descrizione }}</div>
        </template>
      </Dropdown>
    </div>

    <div class="field col">
      <FileUpload ref="fileUploadRef"
                  name="upload[]"
                  mode="basic"
                  :multiple="false"
                  :customUpload="true"
                  :customMode="true"
                  :fileLimit="uploadConfig.fileLimit"
                  :maxFileSize="uploadConfig.maxFileSizeBytes"
                  :show-cancel-button="false"
                  :show-upload-button="false"
                  :auto="true"
                  @uploader="onUpload">
        <template #empty>
          <p>{{ t('message.upload-drag-here') }}</p>
        </template>
      </FileUpload>
    </div>
  </div>

  <div class="mt-2"/>

  <!-- uploaded files -->
  <DataTable :value="uploadedFiles">
    <Column field="nome" :header="t('label.name')"/>

    <!-- tipo -->
    <Column :header="t('label.documentType')">
      <template #body="slotProps">
        {{ slotProps.data.tipo }}
        <HelpInline :hint="slotProps.data.descrizione"/>
      </template>
    </Column>

    <Column field="autore" :header="t('label.autore')"/>
    <Column :header="t('label.dataInserimento')">
      <template #body="slotProps">
        {{ dateTimeFormat(slotProps.data.dataInserimento) }}
      </template>
    </Column>
    <Column :header="t('label.dataModifica')">
      <template #body="slotProps">
        {{ dateTimeFormat(slotProps.data.dataModifica) }}
      </template>
    </Column>

    <Column field="url" :header="t('label.actions')" v-if="showActionsColumn">
      <template #body="slotProps">

        <!-- download -->
        <span class="mr-3" v-if="showDownloadButton">
            <a :href="slotProps.data.url">{{ t('label.download') }}</a>
          </span>

        <!-- delete -->
        <span v-if="showDeleteButton">
            <a @click="deleteFileAction(slotProps.data)" style="cursor: pointer">{{ t('action.delete') }}</a>
          </span>

      </template>
    </Column>
  </DataTable>
</template>

<script setup>
import useAcl from "@/composable/useAcl";
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import tipiDocumentoRestService from "@/services/TipiDocumentoRestService";
import useDateTime from "@/composable/useDateTime";
import HelpInline from "@/components/common/HelpInline";
import Roles from "@/components/common/constants/Roles";


const props = defineProps({
  uploadAction: {type: Function, required: true},
  deleteFileAction: {type: Function, required: true},
  uploadedFiles: {type: Array, required: true,},
  downloadButtonEnabled: {type: Boolean, required: false, default: true},
  deleteButtonEnabled: {type: Boolean, required: false, default: true},
  actionColumnEnabled: {type: Boolean, required: false, default: true},
  context: {type: String, required: true}
});


const {checkPermission} = useAcl();
const store = useStore();
const {t} = useI18n();
const {dateTimeFormat} = useDateTime();

const uploadConfig = computed(() => store.getters['config/upload']);
const fileUploadRef = ref();

const documentTypes = ref([]);
const documentType = ref();

onMounted(async () => {
  documentTypes.value = await tipiDocumentoRestService.findAllByContext(props.context);
  documentType.value = documentTypes.value[0];
})

const onUpload = async (data) => {
  data.documentType = documentType.value.value;

  try {
    await props.uploadAction(data);
  } finally {
    fileUploadRef.value.clear(); // force clear file upload preview
  }
}

const showDownloadButton = computed(() => {
  return props.downloadButtonEnabled &&
      checkPermission([Roles.ADMIN, Roles.BROKER, Roles.SPEDIZIONIERE, Roles.EXECUTION_MANAGER, Roles.SURVEYOR, Roles.CARICATORE, Roles.SCARICATORE]);
})

const showDeleteButton = computed(() => {
  return props.deleteButtonEnabled &&
      checkPermission([Roles.ADMIN, Roles.BROKER, Roles.SPEDIZIONIERE, Roles.EXECUTION_MANAGER, Roles.SURVEYOR, Roles.CARICATORE, Roles.SCARICATORE]);
});

const showActionsColumn = computed(() => {
    return props.actionColumnEnabled;
});

</script>

<style lang="scss" scoped>
.dropdown {

  &.title {
    font-weight: bold;
  }

  &.description {
    font-size: 14px;
    margin-top: 8px;
  }
}

</style>
