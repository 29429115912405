<template>
  <Button
      icon="pi pi-user-edit"
      class="p-button-info mr-2"
      v-tooltip.top="t('action.select-surveyor')"
      @click="showDialog"
      v-if="visible"/>
</template>

<script setup>
import {ref, onMounted} from "vue";
import {useI18n} from "vue-i18n";
import EventService from "@/services/EventService";
import {SHOW_SELECT_SURVEYOR_DIALOG} from "@/components/common/constants/Events";
import {useStore} from "vuex";

const {t} = useI18n();
const store = useStore();

const visible = ref(false);
const props = defineProps({contratto: {required: true}})

onMounted(() => {
  checkVisibility();
})

const showDialog = () => {
  EventService.emit(SHOW_SELECT_SURVEYOR_DIALOG, Object.assign({}, props.contratto));
}

const checkVisibility = async () => {
  const isSellerAndTraportoCaricoBuyer = store.getters['auth/isSeller'] && "buyer" === props.contratto.traportatoreACaricoDel;
  const isBuyerAndTrasportoCaricoSeller = store.getters['auth/isBuyer'] && "seller" === props.contratto.traportatoreACaricoDel;
  visible.value = isSellerAndTraportoCaricoBuyer || isBuyerAndTrasportoCaricoSeller;
}

</script>

<style scoped>

</style>