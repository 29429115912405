import httpClient from "@/services/HttpService";

export default {
    findAll: async () => {
        return (await httpClient.get('/documento/tipi')).data;
    },
    findAllByContext: async (contesto) => {
        return (await httpClient.get(`/documento/tipi/contesto/${contesto}`)).data;
    },
    save: async (tipoDocumento) => {
        return (await httpClient.post('/documento/tipo', tipoDocumento)).data
    },
    contesti: async () => {
        return (await httpClient.get('/documento/tipo/contesti')).data
    }
}
