<template>

  <div class="grid">
    <div class="col-12">
      <div class="card">

        <!-- toolbar -->
        <Toolbar class="mb-4" v-if="checkPermission([Roles.BROKER])">
          <template #start>
            <Button :label="t('action.new')" icon="pi pi-plus" class="p-button-success mr-2"
                    v-if="checkPermission([Roles.BROKER])"
                    @click="showContrattoWizard({})"/>
          </template>
        </Toolbar>

        <DataTable
            dataKey="id"
            v-model:value="contratti"
            v-model:filters="filters"
            :paginator="true"
            :rows="datatableConfig.rows"
            :rowsPerPageOptions="datatableConfig.rowsPerPageOptions"
            :paginatorTemplate="datatableConfig.paginatorTemplate"
            :currentPageReportTemplate="datatableConfig.currentPageReportTemplate"
            responsiveLayout="scroll">

          <template #header>
            <div class="table-header flex flex-column md:flex-row md:justify-content-between">
              <h4 class="m-0 uppercase">{{ t("label.contratto.list") }}</h4>
              <span class="p-input-icon-right">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" :placeholder="t('action.search')"/>
              </span>
            </div>
          </template>

          <Column field="codice" :header="t('label.contratto.riferimenti.numeroContrattoBrokeraggio')"
                  :sortable="true"/>

          <Column field="codiceRifSeller" :header="t('label.numeroRiferimento')" :sortable="true"
                  v-if="checkPermission(['admin', 'seller'])"/>

          <Column field="codiceRifBuyer" :header="t('label.numeroRiferimento')" :sortable="true"
                  v-if="checkPermission(['admin','buyer'])"/>

          <Column field="broker.nomeBreve" :header="t('label.broker')" :sortable="true"
                  v-if="checkPermission(['admin','buyer','seller'])"/>

          <Column field="buyer.nomeBreve" :header="t('label.buyer')" :sortable="true"
                  v-if="checkPermission(['admin','broker','seller'])"/>

          <Column field="seller.nomeBreve" :header="t('label.seller')" :sortable="true"
                  v-if="checkPermission(['admin','broker','buyer'])"/>

          <Column :header="t('label.contratto.merce')" :sortable="true">
            <template #body="slotProps">
              <span v-tooltip.top="slotProps.data.descrizioneMerce">
                {{ truncate(slotProps.data.descrizioneMerce, 10) }}
              </span>
            </template>
          </Column>

          <Column field="quantitaMerce" :header="t('label.contratto.quantita')" :sortable="true"/>

          <Column field="quantitaTreni" :header="t('label.contratto.quantitaTreni')" :sortable="true"/>
          <Column :header="t('label.contratto.consegna')" :sortable="false">
            <template #body="slotProps">
              <span>{{ deliveryDate(slotProps.data.dataInizioConsegna, slotProps.data.dataLimiteConsegna) }}</span>
            </template>
          </Column>

          <!-- confirmed -->
          <Column :sortable="false" v-if="checkPermission([Roles.BROKER])">
            <template #header>
              {{ t('label.contratto.confermato') }}
              <span class="pi pi-question-circle ml-1" v-tooltip.top="t('label.contratto.confermato.help')"/>
            </template>
            <template #body="slotProps">
              <span class="text-center">
                <BooleanIndicator :status="slotProps.data.confirmed" :show-when-false="true"/>
                </span>
            </template>
          </Column>

          <!-- actions -->
          <Column class="text-left">
            <template #body="slotProps">

              <ModificaContrattoButton :contratto="slotProps.data" />
              <UploadFilesContrattoButton :contratto="slotProps.data" />
              <ExportPdfButton :contratto="slotProps.data"/>
              <WorkflowContrattoButton :contratto="slotProps.data" />
              <SelectSecondCustomBrokerButton :contratto="slotProps.data"/>
              <SelectSecondSurveyorButton :contratto="slotProps.data"/>
              <SelectExecutionManagerButton :contratto="slotProps.data"/>
              <SelectCompaniesButton :contratto="slotProps.data"/>

            </template>
          </Column>
        </DataTable>

        <!-- dialogs -->
        <ContrattoDeleteDialog/>
        <ContrattoUploadFileDialog/>

        <SelectCompaniesDialog/>
        <SelectExecutionManagerDialog/>
        <SelectSecondSurveyorDialog/>
        <SelectSecondCustomBrokerDialog/>
        <TimelineStateDiagramDialog/>
        <ConfirmDialog/>
        <SoggettoRichiestaDataDialog/>
      </div>

      <!-- bozze -->
      <ContrattoBozzaTable/>
      <ContrattoDataDialog/>

    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import ContrattoUploadFileDialog from "@/components/contratto/ContrattoUploadFileDialog";
import ContrattoDeleteDialog from "@/components/contratto/ContrattoDeleteDialog";
import {FilterMatchMode} from "primevue/api";
import useDateTime from "@/composable/useDateTime";
import useText from "@/composable/useText";
import {useStore} from "vuex";
import Roles from "@/components/common/constants/Roles";
import {isEmpty} from "lodash";
import {useI18n} from "vue-i18n";
import useAcl from "@/composable/useAcl";
import TimelineStateDiagramDialog from "@/components/common/timeline/TimelineStateDiagramDialog";
import ExportPdfButton from "@/components/contratto/bottoni/ExportPdfButton";
import SelectCompaniesButton from "@/components/contratto/bottoni/SelectCompaniesButton";
import SelectExecutionManagerDialog from "@/components/contratto/SelectExecutionManagerDialog";
import SelectExecutionManagerButton from "@/components/contratto/bottoni/SelectExecutionManagerButton";
import SelectCompaniesDialog from "@/components/contratto/SelectCompaniesDialog";
import router from "@/router";
import ContrattoBozzaTable from "@/components/contratto/ContrattoBozzaTable";
import ContrattoDataDialog from "@/components/contratto/ContrattoDataDialog";
import BooleanIndicator from "@/components/common/BooleanIndicator";
import SelectSecondSurveyorButton from "@/components/contratto/bottoni/SelectSecondSurveyorButton";
import SelectSecondSurveyorDialog from "@/components/contratto/SelectSecondSurveyorDialog";
import SoggettoRichiestaDataDialog from "@/components/anagrafica/soggetto/richieste/SoggettoRichiestaDataDialog";
import SelectSecondCustomBrokerButton from "@/components/contratto/bottoni/SelectSecondCustomBrokerButton";
import SelectSecondCustomBrokerDialog from "@/components/contratto/SelectSecondCustomBrokerDialog";
import ModificaContrattoButton from "@/components/contratto/bottoni/ModificaContrattoButton";
import UploadFilesContrattoButton from "@/components/contratto/bottoni/UploadFilesContrattoButton";
import WorkflowContrattoButton from "@/components/contratto/bottoni/WorkflowContrattoButton";

const {isDate, dateFormat} = useDateTime();
const {truncate} = useText();
const store = useStore();

const {t} = useI18n();
const {checkPermission} = useAcl();

const datatableConfig = store.getters["config/datatable"];

let filters = ref({global: {value: null, matchMode: FilterMatchMode.CONTAINS}});
let contratti = computed(() => store.getters["contratto/items"]);


onMounted(() => {
  store.dispatch("contratto/loadAll");
});


const deliveryDate = (dataInizioConsegna, dataLimiteConsegna) => {
  const SEPARATOR = " - ";
  let deliveryDate = '';
  let dataInizioFormatted = '';
  let dataFineFormatted = '';

  if (isDate(dataInizioConsegna)) {
    dataInizioFormatted = dateFormat(dataInizioConsegna);
  }

  if (isDate(dataLimiteConsegna)) {
    dataFineFormatted = dateFormat(dataLimiteConsegna);
  }
  deliveryDate = dataInizioFormatted;
  deliveryDate += !isEmpty(dataInizioFormatted) || !isEmpty(dataFineFormatted) ? SEPARATOR : '';
  deliveryDate += dataFineFormatted;

  return deliveryDate;
}

async function showContrattoWizard(contratto) {
  await store.dispatch("contrattoWizard/setContratto", contratto);
  await router.push("/contratto/wizard/contratto");
}

</script>

<style scoped lang="scss"></style>
