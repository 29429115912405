<template>
  <Button
      icon="pi pi-paperclip"
      class="p-button-secondary mr-2"
      @click="showUploadFilesDialog"
      v-tooltip.top="t('label.document', 2)"
      v-if="checkPermission([Roles.ADMIN, Roles.BROKER, Roles.EXECUTION_MANAGER, Roles.SPEDIZIONIERE])"/>

</template>

<script setup>
import {useI18n} from "vue-i18n";
import EventService from "@/services/EventService";
import Roles from "@/components/common/constants/Roles";
import {SHOW_CONTRATTO_UPLOAD_FILES_DIALOG} from "@/components/common/constants/Events";
import useAcl from "@/composable/useAcl";

const {t} = useI18n();
const {checkPermission} = useAcl();
const props = defineProps({contratto: {required: true}});

const showUploadFilesDialog = () => {
  EventService.emit(SHOW_CONTRATTO_UPLOAD_FILES_DIALOG, props.contratto);
};

</script>

<style scoped>

</style>