<template>
  <Dialog v-model:visible="show" :style="{width: '450px'}" :header="t('action.confirm')" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span v-if="item">{{ t('message.delete-confirm') }}</span>
    </div>
    <template #footer>
      <Button :label="t('label.no')" icon="pi pi-times" class="p-button-text" @click="hideAction"/>
      <Button :label="t('label.yes')" icon="pi pi-check" class="p-button-text" @click="deleteItemAction"/>
    </template>
  </Dialog>
</template>

<script setup>
import {ref} from "vue";
import eventService from "@/services/EventService";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {useToast} from "primevue/usetoast";

const {t} = useI18n();
const store = useStore();
const toast = useToast();

let item = ref({});
let show = ref(false);

eventService.on("showContrattoDeleteDialog", (currentItem) => {
  show.value = true;
  item.value = currentItem
});

const deleteItemAction = () => {
  store.dispatch("contratto/delete", item.value);
  toast.add({severity: 'success', detail: t('message.delete-success'), life: 3000});
  hideAction();
}

const hideAction = () => {
  show.value = false;
}

</script>

<style scoped>

</style>
