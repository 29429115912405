<template>
  <Button
      icon="pi pi-user"
      class="p-button-success mr-2"
      v-tooltip.top="t('action.select-ex-manager')"
      @click="showSelectExManagerDialog"
      v-if="visible"/>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import EventService from "@/services/EventService";
import {SHOW_SELECT_EXECUTION_MANAGER_DIALOG} from "@/components/common/constants/Events";
import contrattoRestService from "@/services/ContrattoRestService";

const {t} = useI18n();

const props = defineProps({contratto: {required: true}})

const visible = ref(false);

onMounted(() => {
  checkVisibility();
})

const showSelectExManagerDialog = () => {
  const contrattoTmp = Object.assign({}, props.contratto);
  EventService.emit(SHOW_SELECT_EXECUTION_MANAGER_DIALOG, contrattoTmp);
}

const checkVisibility = async () => {
  visible.value = await contrattoRestService.isExecutionManagerSelectable(props.contratto);
}

</script>

<style scoped>

</style>
