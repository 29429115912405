<template>
  <Inplace :closable="true" @close="updateBozza" v-tooltip.top="bozza.noteBozza">
    <template #display>
      {{ truncate(bozza.noteBozza, 30) || t('action.click-to-edit') }}
    </template>
    <template #content>
      <InputText :value="bozza.noteBozza" @input="event => updateNoteBozza(event.target.value)" autoFocus/>
    </template>
  </Inplace>
</template>

<script setup>
import {ref} from "vue";
import useText from "@/composable/useText";
import {useI18n} from "vue-i18n";
import useMessage from "@/composable/useMessage";
import {useStore} from "vuex";

const store = useStore();
const {truncate} = useText();
const {t} = useI18n();
const {success} = useMessage();

const props = defineProps({bozza: {required: true}})
const noteBozza = ref("");

function updateBozza() {
  let tmpBozza = Object.assign({}, props.bozza);
  tmpBozza.noteBozza = noteBozza.value;
  store.dispatch("contratto/saveDraft", tmpBozza);
  success();
}

function updateNoteBozza(nt) {
  noteBozza.value = nt;
}
</script>

<style scoped>

</style>