<template>
  <Button icon="pi pi-pencil"
          @click="showEditItemDialog"
          v-tooltip.top="t('action.edit')"
          class="p-button-primary mr-2"/>
</template>

<script setup>
import EventService from "@/services/EventService";
import {SHOW_CONTRATTO_DATA_DIALOG, UPDATE_ACTION} from "@/components/common/constants/Events";
import {useI18n} from "vue-i18n";

const {t} = useI18n();

const props = defineProps({contratto: {required: true}});

const showEditItemDialog = () => {
  const item = Object.assign({}, props.contratto);
  EventService.emit(SHOW_CONTRATTO_DATA_DIALOG, {mode: UPDATE_ACTION, item});
};

</script>

<style scoped>

</style>