<template>
  <Button
      icon="pi pi-users"
      class="p-button-help mr-2"
      v-tooltip.top="t('action.select-soggetti')"
      @click="showSelectSpedizFerroviarioDialog"
      v-if="visible"/>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import EventService from "@/services/EventService";
import {SHOW_SELECT_AZIENDE_DIALOG} from "@/components/common/constants/Events";
import contrattoRestService from "@/services/ContrattoRestService";

const props = defineProps({contratto: {required: true}})

const {t} = useI18n();

const visible = ref(false);

onMounted(async () => {
  visible.value = await contrattoRestService.areCompaniesSelectable(props.contratto);
})

const showSelectSpedizFerroviarioDialog = () => {
  const contratto = Object.assign({}, props.contratto);
  EventService.emit(SHOW_SELECT_AZIENDE_DIALOG, contratto);
}


</script>

<style scoped>

</style>
