<template>
  <ModalDialog
      v-model:visible="show"
      :header="t('label.insert-execution-manager')"
      position="center"
      :maximizable="false"
      style="width: 30vw; height: 30vh"
      @hide="onHide">

    <!-- email -->
    <div class="field col-12 md:col-12">
      <label for="email">{{ t("label.email") }}</label>
      <InputText id="email" v-model="item.email" :class="errorStyleClass(v$, 'email')"/>
      <ErrorMessages field="email"/>
    </div>

    <!-- actions -->
    <template #footer>
      <Button :label="t('action.cancel')" icon="pi pi-times" class="p-button p-button-light" @click="hideDialog"/>
      <Button :label="t('action.save')" icon="pi pi-check" class="p-button p-button-success p-float-right"
              @click="insertExecutionManager"/>
    </template>

  </ModalDialog>
</template>

<script setup>
import {onMounted, onUnmounted, provide, ref, computed} from "vue";
import EventService from "@/services/EventService";
import {SHOW_INSERT_EXECUTION_MANAGER_DIALOG} from "@/components/common/constants/Events";
import useCommon from "@/composable/useCommon";
import ErrorMessages from "@/components/common/ErrorMessages";
import useVuelidate from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import {useStore} from "vuex";
import restService from "@/services/UtenteRestService";
import {isEmpty} from 'lodash';
import ModalDialog from "@/components/common/ModalDialog";
import {useI18n} from "vue-i18n";
import useLogger from "@/composable/useLogger";


const emit = defineEmits(['on-hide']);

const {t} = useI18n();
const {errorStyleClass} = useCommon();
const {logger} = useLogger();
const store = useStore();
const show = ref(false);
const item = ref({});

let createdExecutionManager = null;
const languages = computed(() => store.getters['config/languages']);

const rules = {
  email: {
    required: helpers.withMessage(t("validation.required", {field: t("label.email")}), required),
    email: helpers.withMessage(t("validation.email", {field: t("label.email")}), email)
  }
};
const v$ = useVuelidate(rules, item);

onMounted(() => {
  EventService.on(SHOW_INSERT_EXECUTION_MANAGER_DIALOG, showDialog);
  store.dispatch("config/loadLanguages");
});

onUnmounted(() => {
  EventService.off(SHOW_INSERT_EXECUTION_MANAGER_DIALOG);
})

const showDialog = () => {
  show.value = true;
}

const hideDialog = () => {
  let onHideParams = null;
  if (!isEmpty(createdExecutionManager)) {
    onHideParams = {executionManager: createdExecutionManager};
  }
  emit("on-hide", onHideParams);

  show.value = false;
}

const onHide = () => {
  v$.value.$reset();
};

const insertExecutionManager = async () => {
  logger.info("insertExecutionManager");

  await v$.value.$validate();
  if (!v$.value.$invalid) {
    const loggedUser = store.getters['auth/loggedUser'];
    const request = {
      email: item.value.email,
      soggetto: loggedUser.soggetto,
      locale: languages.value[0].value
    };
    createdExecutionManager = await restService.insertExecutionManager(request);
    logger.debug("Created execution manager", createdExecutionManager);
    hideDialog();

  } else {
    logger.error("Validation errors ", v$.value.$errors);
  }
}

provide("v$", v$);
</script>

<style scoped>

</style>
